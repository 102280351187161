<template>
  <down-full-up-popup
    :title="$t('OrderInquiry.EvidenceApplication')"
    @close="$emit('close')"
  >
    <div slot="body" class="inner ptb14">
      <div class="wrap_flex pb14 basic_info">
        <div class="input_chk_box" @click="selected = '현금영수증'">
          <input
            id="cash"
            type="radio"
            :checked="selected === '현금영수증'"
            name="receipt"
          />
          <label for="cash" class="color37 font2">{{
            $t('OrderInquiry.CashReceipts')
          }}</label>
        </div>
        <div class="input_chk_box" @click="selected = '세금계산서'">
          <input
            id="tax"
            type="radio"
            :checked="selected === '세금계산서'"
            name="receipt"
          />
          <label for="tax" class="color37 font2">{{
            $t('OrderInquiry.TaxBill')
          }}</label>
        </div>
      </div>
      <!--현금 영수증-->
      <div v-show="selected === '현금영수증'" class="cash_receipt">
        <ul class="wrap_flex basic_info">
          <li>
            <label for="byer_n" class="basic_tit">{{
              $t('OrderInquiry.Buyer', { State: $t('Join.UserName') })
            }}</label>
            <input
              id="byer_n"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.InputName')"
            />
          </li>
          <li>
            <label for="byer_phone" class="basic_tit">{{
              $t('OrderInquiry.Buyer', { State: $t('Join.PhoneNumber') })
            }}</label>
            <input
              id="byer_phone"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterHyphen')"
            />
          </li>
          <li>
            <label for="byer_mail" class="basic_tit"
              >{{ $t('OrderInquiry.Buyer', { State: $t('Join.UserEmail') }) }}
            </label>
            <input
              id="byer_mail"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterEmail')"
            />
          </li>
          <li>
            <label for="byer_tel" class="basic_tit"
              >{{ $t('OrderInquiry.Buyer', { State: $t('OrderInquiry.Tel') }) }}
            </label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterHyphen')"
            />
          </li>
          <li class="wrap_flex">
            <h6 class="basic_tit mb8">
              {{ $t('OrderInquiry.ForCashReceipts') }}
            </h6>
            <div class="input_chk_box p0">
              <label class="color37 font2">
                <input id="" type="checkbox" />
                {{ $t('OrderInquiry.ForPersonal') }}
              </label>
            </div>
            <div class="input_chk_box p0">
              <label class="color37 font2">
                <input id="" type="checkbox" name="cash_" />
                {{ $t('OrderInquiry.BusinessSpending') }}
              </label>
            </div>
          </li>
          <li class="mt14">
            <label for="buis_num" class="basic_tit">{{
              $t('OrderInquiry.CompanyNumber')
            }}</label>
            <input
              id="buis_num"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterHyphen')"
            />
          </li>
        </ul>
      </div>
      <!--세금계산기-->
      <div v-show="selected === '세금계산서'" class="tax_receipt">
        <ul class="wrap_flex basic_info">
          <li>
            <label for="byer_n" class="basic_tit">{{
              $t('OrderInquiry.Manager', { State: $t('Join.PhoneNumber') })
            }}</label>
            <input
              id="byer_n"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.InputName')"
            />
          </li>
          <li>
            <label for="byer_phone" class="basic_tit">{{
              $t('OrderInquiry.Manager', { State: $t('Join.PhoneNumber') })
            }}</label>
            <input
              id="byer_phone"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterHyphen')"
            />
          </li>
          <li>
            <label for="byer_mail" class="basic_tit">{{
              $t('OrderInquiry.Manager', { State: $t('Join.UserEmail') })
            }}</label>
            <input
              id="byer_mail"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterEmail')"
            />
          </li>
          <li>
            <label for="byer_tel" class="basic_tit">{{
              $t('OrderInquiry.Manager', { State: $t('OrderInquiry.Tel') })
            }}</label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterHyphen')"
            />
          </li>
          <li>
            <label for="byer_tel" class="basic_tit">{{
              $t('OrderInquiry.CompanyNumber')
            }}</label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('Join.EnterHyphen')"
            />
          </li>
          <li>
            <label for="byer_tel" class="basic_tit">{{
              $t('OrderInquiry.Mutual')
            }}</label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('OrderInquiry.AlertMutual')"
            />
          </li>

          <li>
            <label for="byer_tel" class="basic_tit">{{
              $t('OrderInquiry.Representative')
            }}</label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('OrderInquiry.AlertRepresentative')"
            />
          </li>
          <li>
            <label for="byer_tel" class="basic_tit">{{
              $t('OrderInquiry.Business')
            }}</label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('OrderInquiry.AlertBusiness')"
            />
          </li>
          <li>
            <label for="byer_tel" class="basic_tit">{{
              $t('OrderInquiry.Sectors')
            }}</label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('OrderInquiry.AlertSectors')"
            />
          </li>

          <li>
            <label for="byer_tel" class="basic_tit">{{
              $t('OrderInquiry.BusinessAddress')
            }}</label>
            <input
              id="byer_tel"
              type="text"
              class="deep_type"
              :placeholder="$t('OrderInquiry.AlertBusinessAddress')"
            />
          </li>
        </ul>
      </div>
    </div>
    <template slot="footer">
      <button type="reset" class="" @click="$emit('close')">
        {{ $t('MyStyle.Cancel') }}
      </button>
      <button type="submit" class="" @click="handleClickSubmit">
        {{ $t('Ect.Request') }}
      </button>
    </template>
  </down-full-up-popup>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import DownFullUpPopup from '../../../../components/popups/DownFullUpPopup.vue';
// import useAlert from '@Composables/dialogs/useAlert';

export default {
  components: { DownFullUpPopup },
  props: ['type'],
  setup(props) {
    // const { showAlert } = useAlert();
    const state = reactive({
      selected: props.type,
      formData: {},
    });

    return {
      ...toRefs(state),
      handleClickSubmit() {
        console.log('증빙셔류 신청');
      },
    };
  },
};
</script>

<style></style>
