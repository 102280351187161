<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.OrderInquiry')" />

    <div class="layout_body box_p60">
      <div class="list_layout">
        <period-search v-model="date" @change="handleChangeGigan">
          <div class="bg_f7 font2 mt14 mb6">
            {{ $t("OrderInquiry.OrderInquiryPeriod") }}
          </div>
        </period-search>
        <div v-if="statistics" class="inner ptb10 border_top">
          <div class="ico_desc delivery">
            <ul id="myBtnContainer" class="disflex">
              <li class="active">
                <div>
                  <div class="desc_img deposit"></div>
                  <div class="desc_txt" @click="handleChangeStatus('REQUEST')">
                    <p>{{ $t("OrderInquiry.BeforeDeposit") }}</p>
                    <p>{{ statistics["REQUEST"] }}</p>
                  </div>
                </div>
              </li>
              <li class="current">
                <div>
                  <div class="desc_img ready"></div>
                  <div
                    class="desc_txt"
                    @click="
                      handleChangeStatus(
                        'PAYMENT,DELIVERY_READY,DELIVERY_DELAY,DELIVERY_DELAY_HOLD'
                      )
                    "
                  >
                    <p>{{ $t("OrderInquiry.PreparingDelivery") }}</p>
                    <p>{{ statistics["DELIVERY_READY"] }}</p>
                  </div>
                </div>
              </li>
              <li class="current">
                <div>
                  <div class="desc_img shipping"></div>
                  <div class="desc_txt" @click="handleChangeStatus('DELIVERY')">
                    <p>{{ $t("OrderInquiry.Shipping") }}</p>
                    <p>{{ statistics["DELIVERY"] }}</p>
                  </div>
                </div>
              </li>
              <li class="current">
                <div>
                  <div class="desc_img complete"></div>
                  <div
                    class="desc_txt"
                    @click="handleChangeStatus('DELIVERY_COMPLETE,COMPLETE')"
                  >
                    <p>{{ $t("OrderInquiry.DeiliveryComplete") }}</p>
                    <p>{{ statistics["DELIVERY_COMPLETE"] }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--1.전체목록 시작-->
        <div v-if="items.length > 0" class="order_list_layout container">
          <!--주문 배송 현황에 따라 변화/전체 목록에서는 보이지 않음ㄱ-->
          <!-- <h6 class="bold_tit pl9">배송준비중 1</h6> -->
          <div v-for="item in items" :key="item.order_id">
            <h3
              class="order_date"
              style="
                font-size: 18px;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;
              "
            >
              <router-link
                :to="`/mypage/order/${item.order_id}`"
                style="
                  width: 100%;
                  font-size: 18px;
                  margin-bottom: 10px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
              >
                {{ moment(item.ins_dtm).format("YYYY-MM-DD") }}
                <span
                  style="
                    display: inline-block;
                    font-weight: bold;
                    margin-left: 20px;
                  "
                >
                  {{ item.order_id }}
                </span>
              </router-link>
              <img alt="" src="@/assets/images/design/back_r_icon.svg" />
            </h3>
            <div>
              <order-item
                ref="orderUpDate"
                :item="item"
                type="detail"
                @orderItemUpdate="handleUpdateOrderItem"
              />
            </div>
          </div>

          <button
            v-show="hasMore"
            class="more"
            type="button"
            @click="handleClickMore"
          >
            {{ $t("Ect.More") }}
          </button>
        </div>

        <!--주문내역이 없을 때ㄱ-->
        <p v-else class="middle_txt" style="padding-bottom: 43px">
          {{ $t("OrderInquiry.NoneContent") }}
        </p>

        <!--1.전체목록 끝-->
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t("OrderInquiry.OrderTip") }}</h6>
          <div class="inner">
            <ul class="order_tip">
              <li>{{ $t("OrderInquiry.OrderDescription1") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription2") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription3") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription4") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription5") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <sild-footer isToggleNav="true" />
  </div>
</template>

<script>
import BackTitleBasket from "@Components/layouts/components/headers/BackTitleBasket.vue";
import SildFooter from "@Components/layouts/components/footers/SildFooter.vue";
import { onMounted, reactive, ref, toRefs } from "vue-demi";
import OrderAPI from "@/apis/OrderAPI";
import useUserInfo from "@/composables/user/useUserInfo";
import DateUtils from "@/utils/DateUtils";
import OrderItem from "@/components/ui/OrderItem/OrderItem.vue";
import PeriodSearch from "../../../components/Inputs/PeriodSearch/PeriodSearch.vue";
import moment from "moment";

export default {
  computed: {
    moment() {
      return moment;
    },
  },
  components: { BackTitleBasket, SildFooter, OrderItem, PeriodSearch },

  setup() {
    const orderUpDate = ref(orderUpDate);
    const { info } = useUserInfo();

    const state = reactive({
      items: [],
      total: 0,
      statistics: null,
      hasMore: true,
      gigan: 0,
      orders: null,
      date: [],
      ins_dtm: [],
      new_ins_dtm: [],
    });

    const params = {
      mem_id: info?.mem_id,
      page: 0,
      limit: 10,
      status: "PAYMENT",
    };

    const loadStatistics = async () => {
      const { data } = await OrderAPI.getMyOrderStatistics(params);
      console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
      console.log(data);
      console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
      const statistics = {
        REQUEST: "-",
        DELIVERY_READY: "-",
        DELIVERY: "-",
        DELIVERY_COMPLETE: "-",
      };

      data.result_data.forEach((item) => {
        statistics[item.status] = item.count;
      });

      state.statistics = statistics;
    };

    const loadOrderItems = async () => {
      const { data } = await OrderAPI.getMyOrderItems(params);
      console.log("#####################");

      console.log("#####################");

      data.result_data.forEach((result_data) => {
        state.ins_dtm = [];
        state.ins_dtm.push(result_data.ins_dtm.substr(0, 10));
      });

      state.new_ins_dtm = state.ins_dtm.filter(
        (item, pos) => state.ins_dtm.indexOf(item) === pos
      );
      state.total = data.items_total;
      state.orders = data.orders;

      groupByOrder(data.result_data);

      // state.hasMore = state.total > state.items.length && !params.status;
      state.hasMore = state.total > data.result_data.length * params.page + 10;
    };

    const groupByOrder = (orders) => {
      const items = {};

      orders.forEach((item) => {
        // console.log(item)
        item.order = state.orders.find(
          (order) => order.order_id === item.order_id
        );

        // if (params.status !== undefined && params.status !== item.status) {
        //   return;
        // }
        if (params.status !== undefined) {
          // 주문상태값이 여러개일 경우 배열로 변환 후 확인
          const statusArray = Array.isArray(params.status)
            ? params.status
            : params.status.split(",");

          if (!statusArray.includes(item.status)) {
            return;
          }
        }

        // 주문
        if (items[item.order_id] === undefined) {
          items[item.order_id] = {
            order_id: item.order_id,
            ins_dtm: item.ins_dtm,
            use_point: item.order.use_point,
            delivery_price: item.order.delivery_price,
            provider: {},
          };
        }

        const order = items[item.order_id];

        // 브랜드
        // console.log('brand', order['provider'][item.den_id]);
        if (order["provider"][item.den_id] === undefined) {
          order["provider"][item.den_id] = {
            den_id: item.den_id,
            den_name: item.den_name,
            provider_summary: item.den_en_name,
            seller_picture: item.seller_picture,
            img_url: item.img_url,
            cs_no: item.cs_no,
            items: [],
          };
        }

        order["provider"][item.den_id].items.push(item);
      });

      const groupItems = [];

      Object.keys(items).forEach((key) => {
        groupItems.push(items[key]);
      });

      groupItems.sort((a, b) => {
        const createDateA = DateUtils.dttmToNumber(a.ins_dtm);
        const createDateB = DateUtils.dttmToNumber(b.ins_dtm);

        return createDateB - createDateA;
      });

      if (params.page == 0) {
        state.items = [];
      }

      state.items = [...state.items, ...groupItems];

      state.ins_dtm = state.items.map((item) => {
        return item.ins_dtm.substr(0, 10);
      });
    };

    onMounted(() => {
      loadStatistics();
    });

    return {
      orderUpDate,
      ...toRefs(state),

      handleClickMore() {
        params.page++;
        loadOrderItems();
      },

      async handleChangeStatus(status) {
        state.items = [];
        params.page = 0;
        params.status = status;

        await loadOrderItems();

        // state.hasMore = false;
      },

      handleChangeGigan(data) {
        params.page = 0;

        params.startDate = data[0];
        params.endDate = data[1];

        loadOrderItems();
        loadStatistics();
      },
      handleUpdateOrderItem() {
        loadStatistics();
      },
    };
  },
};
</script>

<style scoped>
.more {
  border: 1px solid #ccc;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}
</style>
