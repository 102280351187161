<template>
  <div class="wrap">
    <!-- header : END -->
    <BackTitleBasket :title="$t('OrderInquiry.OrderDetail')" />
    <!-- header : END -->
    <!-- body : START -->
    <div v-if="order" class="layout_body box_p60">
      <div class="list_layout detail_condition">
        <div class="group_order_list">
          <div class="inner ptb14 border_bottom flex_col">
            <span class="font2 colora8">{{ order.ins_dtm.substr(0, 10) }}</span>
            <strong
              >{{ $t("OrderInquiry.OrderNumber") }} {{ order.order_id }}</strong
            >
          </div>
          <div>
            <order-item
              v-for="item in items"
              :key="item.order_id"
              :isShowOrder="false"
              :isShowOrderID="false"
              :item="item"
            />
          </div>
        </div>
        <div class="border_top mt20">
          <h6 class="allPage_tit">{{ $t("OrderInquiry.PaymentInfo") }}</h6>
          <div class="inner p04 table_wrap ver2">
            <div>
              <table class="table_box">
                <colgroup>
                  <col style="width: 27%" />
                  <col style="width: 73%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ $t("OrderInquiry.OrderNumber") }}</th>
                    <td>{{ order.order_id }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("OrderInquiry.OrderState") }}</th>
                    <td>
                      {{ OrderStatus[order.status] }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("OrderInquiry.PaymentDate") }}</th>
                    <td v-if="order.applDate && order.applTime">
                      {{ order.payTime }}
                    </td>
                    <td v-else>-</td>
                  </tr>
                  <tr>
                    <th>{{ $t("OrderInquiry.PaymentType") }}</th>
                    <td>{{ getPayName(order.payMethod) }}</td>
                  </tr>
                  <tr v-if="order.payMethod === 'CARD'">
                    <th>{{ $t("OrderInquiry.CardInfo") }}</th>
                    <td>
                      {{ order.card_name }} / {{ order.card_num }} /
                      {{ order.card_quota }}
                    </td>
                  </tr>

                  <tr>
                    <th>{{ $t("OrderInquiry.ProductTotalAmount") }}</th>
                    <td>₩ {{ order.tatal_cit_price.numberFormat() }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("Basket.DiscountAmount") }}</th>
                    <td>₩ {{ order.tatal_sale_price.numberFormat() }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t("Mypage.Point") }}</th>
                    <td>
                      {{
                        order.use_point > 0
                          ? Number(order.tatal_use_point).numberFormat() + "P"
                          : "₩ 0"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("Basket.DeliveryFee") }}</th>
                    <td>₩ {{ order.delivery_price.numberFormat() }}</td>
                  </tr>

                  <tr>
                    <th>{{ $t("OrderInquiry.FinalAmount") }}</th>
                    <td>₩ {{ order.total_price.numberFormat() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="border_top order_discount">
          <div class="allPage_tit">
            <h6>{{ $t("Delivery.DeliveryInfo") }}</h6>
          </div>
          <div class="inner p04">
            <div class="table_wrap">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t("OrderInquiry.Recipient") }}</th>
                      <td>{{ order.recipient_name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("Delivery.PhoneNumber") }}</th>
                      <td>{{ phoneFormat(order.recipient_phone) }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("Delivery.address") }}</th>
                      <td>
                        {{ order.recipient_zip }}
                        {{ order.recipient_addr1 }}
                        {{ order.recipient_addr2 }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t("OrderInquiry.ShippingMemo") }}</th>
                      <td>{{ order.recipient_memo }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="font2 align_center mt10">
                {{ $t("OrderInquiry.AlertShipping") }}
              </p>
            </div>
          </div>
          <div class="mb30 btw_btn">
            <router-link
              class="white_btn font2"
              to="/mypage/order-delivery-search"
            >
              {{ $t("Ect.List") }}
            </router-link>
            <router-link class="black_btn font2" to="/">
              {{ $t("Ect.Main") }}
            </router-link>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t("OrderInquiry.OrderTip") }}</h6>
          <div class="inner">
            <ul class="order_tip">
              <li>{{ $t("OrderInquiry.OrderDescription1") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription2") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription3") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription4") }}</li>
              <li>{{ $t("OrderInquiry.OrderDescription5") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <sort-popup
      v-if="isShowProof"
      :items="proofs"
      :title="$t('OrderInquiry.EvidenceSelect')"
      @close="isShowProof = false"
      @select="handleSelectProof"
    />
    <request-proof
      v-if="isShowRequestProof"
      :type="selectedProof"
      @close="handleCloseRequestProof"
    />
  </div>
</template>

<script>
import OrderAPI from "@/apis/OrderAPI";
import useUserInfo from "../../../composables/user/useUserInfo";
import { reactive, toRefs } from "vue-demi";
import BackTitleBasket from "@/components/layouts/components/headers/BackTitleBasket.vue";
import DateUtils from "@/utils/DateUtils";
import OrderItem from "../../../components/ui/OrderItem/OrderItem.vue";
import OrderStatus from "@/consts/OrderStatus";
import moment from "moment";
import SortPopup from "../../../components/popups/SortPopup.vue";
import RequestProof from "./popups/RequestProof.vue";
// import useAlert from '@Composables/dialogs/useAlert';

export default {
  components: { BackTitleBasket, OrderItem, SortPopup, RequestProof },
  setup(_, context) {
    const { info } = useUserInfo();

    const state = reactive({
      items: null,
      order: null,
      selectedProof: null,
      isShowProof: false,
      isShowRequestProof: false,
    });

    const groupByOrder = (orders) => {
      const items = {};
      console.log("@@@@@@@@@@@@@@@@@@@@@@@");
      console.log(orders);
      console.log("@@@@@@@@@@@@@@@@@@@@@@@");

      orders.forEach((item) => {
        item.is_detail = true;
        item.order = state.order;
        item.delivery_price = item.delivery_price_value;

        if (params.status !== undefined && params.status !== item.status) {
          return;
        }

        // 주문
        if (items[item.order_id] === undefined) {
          items[item.order_id] = {
            order_id: item.order_id,
            ins_dtm: item.ins_dtm,
            use_point: state.order.use_point,
            delivery_price: state.order.delivery_price,
            provider: {},
          };
        }

        const order = items[item.order_id];

        // 브랜드
        if (order["provider"][item.den_id] === undefined) {
          order["provider"][item.den_id] = {
            den_id: item.den_id,
            den_name: item.den_name,
            seller_picture: item.seller_picture,
            img_url: item.img_url,
            cs_no: item.cs_no,
            items: [],
          };
        }

        order["provider"][item.den_id].items.push(item);
      });

      const groupItems = [];

      Object.keys(items).forEach((key) => {
        groupItems.push(items[key]);
      });

      groupItems.sort((a, b) => {
        const createDateA = DateUtils.dttmToNumber(a.ins_dtm);
        const createDateB = DateUtils.dttmToNumber(b.ins_dtm);

        return createDateB - createDateA;
      });

      if (params.page == 0) {
        state.items = [];
      }

      state.items = [...state.items, ...groupItems];
    };
    console.log(state.items, state.item, "cjt!@!!!");
    const params = {
      order_id: context.root.$route.params.id,
      mem_id: info?.mem_id,
      page: 0,
      limit: 1000,
    };

    const loadOrderItems = async () => {
      const { data } = await OrderAPI.getMyOrderItems(params);
      console.log("************************************");
      console.log(data);
      console.log(data.result_data);
      console.log("************************************");
      state.total = data.total;

      const order = data.orders[0];
      console.log(order.payTime);
      console.log(data.orders[0]);
      order.payTime = moment(`20${order.applDate} ${order.applTime}`).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      state.order = order;

      groupByOrder(data.result_data);
      if (order.card_quota === "00") {
        order.card_quota = "일시불";
      } else if (order.card_quota !== null) {
        order.card_quota.replace(/(^0+)/, "");
      }
    };

    loadOrderItems();

    return {
      OrderStatus,
      proofs: [
        {
          key: "현금영수증",
          label: context.root.$t("OrderInquiry.CashReceipts"),
        },
        {
          key: "세금계산서",
          label: context.root.$t("OrderInquiry.TaxBill"),
        },
      ],
      ...toRefs(state),
      getPayName(method) {
        switch (method) {
          case "CARD":
            return context.root.$t("OrderInquiry.Card");
          case "NAVER":
            return context.root.$t("OrderInquiry.NaverPay");
          case "KAKAO":
            return context.root.$t("OrderInquiry.KakaoPay");
          case "VBANK":
            return context.root.$t("OrderInquiry.VirtualAccount");
          case "BANK":
            return context.root.$t("OrderInquiry.BankTransfer");
          default:
            return "";
        }
      },
      phoneFormat(phone) {
        return [
          phone.substr(0, 3),
          phone.substr(3, 4),
          phone.substr(7, 4),
        ].join("-");
      },

      handleSelectProof(item) {
        state.selectedProof = item.key;
        state.isShowRequestProof = true;
      },

      handleCloseRequestProof() {
        state.isShowProof = false;
        state.isShowRequestProof = false;
      },
    };
  },
};
</script>

<style></style>
