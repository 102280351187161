<template>
  <div class="wrap">
    <back-title-basket :title="$t('CancelList.ReturnDetail')" />
    <div class="layout_body box_p60">
      <div class="list_layout status detail_condition">
        <div class="group_order_list">
          <div class="inner border_bottom flex_col">
            <span v-if="returnItem" class="font2 colora8">{{
              returnItem.payTime
            }}</span>
            <span v-if="changeItem" class="font2 colora8">{{
              changeItem.payTime
            }}</span>
            <strong v-if="returnItem"
              >{{ $t('OrderInquiry.OrderNumber') }}
              {{ returnItem.order_id }}</strong
            >
            <strong v-if="changeItem"
              >{{ $t('OrderInquiry.OrderNumber') }}
              {{ changeItem.order_id }}</strong
            >
          </div>
          <order-item
            v-for="item in items"
            :key="item.order_id"
            :isShowOrder="false"
            :item="item"
          />
        </div>
      </div>
      <Div v-if="returnItem">
        <div class="border_top mt20">
          <h6 class="allPage_tit">{{ $t('CancelList.CancelReason') }}</h6>
          <div class="inner p04 table_wrap ver2">
            <div>
              <table class="table_box">
                <colgroup>
                  <col style="width: 27%" />
                  <col style="width: 73%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowspan="2">{{ $t('CancelList.CancelReason') }}</th>
                    <td>{{ returnItem.cancel_reason }}</td>
                  </tr>
                  <tr>
                    <td>
                      {{
                        returnItem.cancel_memo ? returnItem.cancel_memo : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="border_top mt30">
          <h6 class="allPage_tit">{{ $t('CancelList.RefundInfo') }}</h6>
          <div class="inner p04 btw_mbtn">
            <div class="table_wrap ver3 pb10">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t('CancelList.ReturnDate') }}</th>
                      <td>{{ returnItem.request_dtm }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.ReturnNum') }}</th>
                      <td>
                        {{ returnItem.crf_id불 }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.CompleteDate') }}</th>
                      <td>
                        {{
                          returnItem.complete_dtm
                            ? returnItem.complete_dtm
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.RefundType') }}</th>
                      <td v-if="returnItem.payName === '카드결제'">
                        {{ returnItem.payName }} ₩
                        {{
                          Math.sign(refundAmount) === 1
                            ? -refundAmount
                            : refundAmount
                        }}
                        <br />
                        ({{
                          returnItem.card_name ? returnItem.card_name : '-'
                        }}
                        / {{ returnItem.card_num }} )<br />
                        포인트
                        {{
                          returnItem.use_point_value
                            ? returnItem.use_point_value
                            : 0
                        }}P<br />
                        쿠폰 {{ returnItem.use_coupon_value }}₩<br />
                      </td>
                      <td v-else>
                        {{ returnItem.payName }}<br />
                        ({{ returnItem.vbank_name }} /
                        {{ returnItem.vbank_num }} )<br />
                        포인트
                        {{
                          returnItem.use_point_value
                            ? returnItem.use_point_value
                            : 0
                        }}P<br />
                        쿠폰 {{ returnItem.use_coupon_value }}₩<br />
                      </td>
                    </tr>
                    <!--환불 금액-->
                    <tr class="refund_pri">
                      <th>{{ $t('CancelList.RefundAmount') }}</th>
                      <td>
                        ₩
                        {{ refundAmount }}
                      </td>
                    </tr>
                    <tr class="evidence">
                      <th>{{ $t('OrderInquiry.Evidence') }}</th>
                      <td class="evidence p0">
                        <a
                          v-if="returnItem.status !== 'RETURN_REQUEST'"
                          :href="`https://npg.nicepay.co.kr/issue/IssueLoader.do?type=${returnItem.rcptType}&TID=${returnItem.tid}`"
                          class="black_btn"
                          onClick="window.open(this.href, '', 'width=400, height=430'); return false;"
                          target="_blank"
                        >
                          {{ $t('CancelList.Cancellation') }}
                        </a>
                        <p v-else style="padding-left: 20px">
                          반품 요청중 입니다.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mb30 btw_btn">
            <router-link
              class="white_btn font2"
              to="/mypage/status-change-order"
              >{{ $t('Ect.List') }}
            </router-link>
            <router-link class="black_btn font2" to="/"
              >{{ $t('Ect.Main') }}
            </router-link>
          </div>
        </div>
        <div class="border_top mt30">
          <h6 class="allPage_tit">{{ $t('CancelList.CancelTitle1') }}</h6>
          <div class="inner p04 btw_mbtn">
            <div class="table_wrap ver2">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t('CancelList.CreditCard') }}</th>
                      <td>{{ $t('CancelList.Cancel') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.Payment') }}</th>
                      <td>
                        {{ $t('CancelList.PurchaseCancel') }}
                      </td>
                    </tr>
                    <tr>
                      <th v-html="$t('CancelList.VirtualAccount')"></th>
                      <td>{{ $t('CancelList.NextDay') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.RealTimeTransfer') }}</th>
                      <td>{{ $t('CancelList.RealTimeDeposit') }}</td>
                    </tr>
                    <tr class="evidence">
                      <th>{{ $t('CancelList.PhonePayment') }}</th>
                      <td v-html="$t('CancelList.PhoneDescription')"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Div>
      <Div v-if="changeItem">
        <div class="border_top mt20">
          <h6 class="allPage_tit">{{ $t('CancelList.CancelReason') }}</h6>
          <div class="inner p04 table_wrap ver2">
            <div>
              <table class="table_box">
                <colgroup>
                  <col style="width: 27%" />
                  <col style="width: 73%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowspan="2">{{ $t('CancelList.CancelReason') }}</th>
                    <td>{{ changeItem.change_reason }}</td>
                  </tr>
                  <tr>
                    <td>
                      {{
                        changeItem.change_memo ? changeItem.change_memo : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="border_top mt30">
          <h6 class="allPage_tit">{{ $t('CancelList.ExchangeInfo') }}</h6>
          <div class="inner p04 btw_mbtn">
            <div class="table_wrap ver3 pb10">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t('CancelList.ExchangeDate') }}</th>
                      <td>{{ changeItem.request_dtm }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.ExchangeNum') }}</th>
                      <td>{{ changeItem.ccg_id }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.CompleteDate') }}</th>
                      <td>
                        {{
                          changeItem.delivery_complete_dtm
                            ? changeItem.delivery_complete_dtm
                            : '-'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="border_top mt30">
          <h6 class="allPage_tit">상품 회수 및 수령 정보</h6>
          <div class="inner p04 btw_mbtn">
            <div class="table_wrap ver3 pb10">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>상품회수 진행여부</th>
                      <td>{{ changeItem.status_name }}</td>
                    </tr>
                    <tr>
                      <th>보내시는분</th>
                      <td>{{ changeItem.recipient_name }}</td>
                    </tr>
                    <tr>
                      <th>휴대전화번호</th>
                      <td>{{ changeItem.recipient_phone1 }}</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td>
                        {{ changeItem.recipient_zip }}
                        {{ changeItem.recipient_addr1 }}
                        {{ changeItem.recipient_addr2 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mb30 btw_btn">
            <router-link
              class="white_btn font2"
              to="/mypage/status-change-order"
              >{{ $t('Ect.List') }}
            </router-link>
            <router-link class="black_btn font2" to="/"
              >{{ $t('Ect.Main') }}
            </router-link>
          </div>
        </div>
        <div class="border_top mt30">
          <h6 class="allPage_tit">{{ $t('CancelList.CancelTitle1') }}</h6>
          <div class="inner p04 btw_mbtn">
            <div class="table_wrap ver2">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t('CancelList.CreditCard') }}</th>
                      <td>{{ $t('CancelList.Cancel') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.Payment') }}</th>
                      <td>
                        {{ $t('CancelList.PurchaseCancel') }}
                      </td>
                    </tr>
                    <tr>
                      <th v-html="$t('CancelList.VirtualAccount')"></th>
                      <td>{{ $t('CancelList.NextDay') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.RealTimeTransfer') }}</th>
                      <td>{{ $t('CancelList.RealTimeDeposit') }}</td>
                    </tr>

                    <tr class="evidence">
                      <th>{{ $t('CancelList.PhonePayment') }}</th>
                      <td v-html="$t('CancelList.PhoneDescription')"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Div>
    </div>
    <sild-footer />
  </div>
</template>
<script>
import OrderAPI from '@/apis/OrderAPI';
import { computed, reactive, toRefs } from 'vue-demi';
import SildFooter from '../../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import DateUtils from '@/utils/DateUtils';
import useUserInfo from '../../../composables/user/useUserInfo';
// import moment from 'moment';
import OrderItem from '../../../components/ui/OrderItem/OrderItem.vue';
import { changeStatus, returnStatus } from '@/consts/OrderStatus';
import moment from 'moment/moment';

export default {
  components: { BackTitleBasket, SildFooter, OrderItem },
  setup(_, context) {
    const { info } = useUserInfo();

    const state = reactive({
      items: null,
      order: null,
      returnItem: null,
      changeItem: null,
      selectedProof: null,
      isShowProof: false,
      isShowRequestProof: false,
      changeStatus: null,
      deliveryFee: null,
      refundFee: null,
      refundAmount: 0,
      amountIWillReceive: 0,
    });
    const totalFee = computed(() => {
      const totalDeliverys = Number(state.deliveryFee);
      return totalDeliverys;
    });
    const groupByOrder = (orders) => {
      const items = {};

      orders.forEach((item) => {
        item.is_detail = true;
        // item.order = state.order;

        const changeValue = changeStatus.includes(item.status);
        const returnValue = returnStatus.includes(item.status);
        // console.log(item);
        // console.log(value);
        if (changeValue && item.ccg_id === context.root.$route.params.cod_id) {
          loadChangeItems();
        } else if (
          returnValue &&
          item.crf_id === context.root.$route.params.cod_id
        ) {
          loadReturnItems();
        } else {
          return false;
        }

        // 주문

        if (changeValue && items[item.ccg_id] === undefined) {
          items[item.ccg_id] = {
            order_id: item.order_id,
            cod_id: item.cod_id,
            ccg_id: item.ccg_id,
            ins_dtm: item.ins_dtm,
            use_point: item.use_point_value,
            use_coupon: item.use_coupon_value,
            use_grade: item.use_grade_value,
            delivery_price: item.delivery_type_fee,
            provider: {},
          };
        }
        console.log('================');
        console.log(item);
        console.log(items);
        console.log('================');
        if (returnValue && items[item.crf_id] === undefined) {
          items[item.crf_id] = {
            order_id: item.order_id,
            cod_id: item.cod_id,
            crf_id: item.crf_id,
            ins_dtm: item.ins_dtm,
            use_point: item.use_point_value,
            use_coupon: item.use_coupon_value,
            use_grade: item.use_grade_value,
            delivery_price: item.delivery_type_fee,
            return_delivery_fee: item.return_delivery_fee,
            provider: {},
          };
        }
        state.deliveryFee = item.delivery_type_fee;
        state.refundFee = item.return_delivery_fee;
        console.log(state.deliveryFee, state.refundFee);
        const order = items[`${changeValue ? item.ccg_id : item.crf_id}`];

        console.log(order, 'orderorderorderorderorder');

        // 브랜드
        // 브랜드
        if (order['provider'][item.den_id] === undefined) {
          order['provider'][item.den_id] = {
            den_id: item.den_id,
            den_name: item.den_name,
            seller_picture: item.seller_picture,
            img_url: item.img_url,
            cs_no: item.cs_no,
            items: [],
          };
        }

        order['provider'][item.den_id].items.push(item);
      });

      const groupItems = [];

      Object.keys(items).forEach((key) => {
        groupItems.push(items[key]);
      });
      console.log(groupItems);
      groupItems.sort((a, b) => {
        const createDateA = DateUtils.dttmToNumber(a.ins_dtm);
        const createDateB = DateUtils.dttmToNumber(b.ins_dtm);

        return createDateB - createDateA;
      });

      if (params.page == 0) {
        state.items = [];
      }

      state.items = [...state.items, ...groupItems];
      console.log(state.items);
      state.items = state.items.filter((item) => {
        if (item.ccg_id === context.root.$route.params.cod_id) {
          return true;
        }
        if (item.crf_id === context.root.$route.params.cod_id) {
          return true;
        }
      });
      console.log(state.items);
    };

    const params = {
      order_id: context.root.$route.params.order_id,
      mem_id: info?.mem_id,
      page: 0,
      limit: 1000,
      status: 'change',
    };
    const params_2 = {
      order_id: context.root.$route.params.order_id,
      mem_id: info?.mem_id,
      page: 0,
      limit: 1000,
      status: 'refund',
    };

    console.log(context.root.$route.params.order_id);

    const loadOrderItems_2 = async () => {
      const { data } = await OrderAPI.getMyOrderItemsByStatus(params_2);
      state.total = data.total;
      // state.orders = data.orders;

      return data;
    };
    const loadOrderItems = async () => {
      const { data } = await OrderAPI.getMyOrderItemsByStatus(params);

      state.total = data.total;
      // state.orders = data.orders;

      //
      // state.order = order;
      // console.log(state.order);
      // groupByOrder(data.result_data);
      return data;
    };
    const loadReturnItems = async () => {
      const { data } = await OrderAPI.getReturnRequest({
        order_id: context.root.$route.params.order_id,
        mem_id: info?.mem_id,
      });
      const returnItems = data.data.filter((item) => {
        return item.crf_id === context.root.$route.params.cod_id;
      });
      state.returnItem = { ...returnItems[0] };

      console.log('@@@@@@@@@@@@@@@@@@@@@@@@');
      console.log(state.returnItem);
      console.log('@@@@@@@@@@@@@@@@@@@@@@@@');

      /*환불금액*/
      state.refundAmount = state.returnItem.refund_price
        ? Number(state.returnItem.return_total_price).numberFormat()
        : '0';
      state.returnItem.payTime = new Date(state.returnItem.request_dtm);
      state.returnItem.payTime = moment(state.returnItem.payTime).format(
        'YYYY-MM-DD'
      );
    };
    const loadChangeItems = async () => {
      const { data } = await OrderAPI.getChangeDetailRequest({
        order_id: context.root.$route.params.order_id,
        mem_id: info?.mem_id,
      });

      const changeItems = data.data.filter((item) => {
        return item.ccg_id === context.root.$route.params.cod_id;
      });
      state.changeItem = { ...changeItems[0] };
      state.changeItem.payTime = new Date(state.changeItem.request_dtm);
      state.changeItem.payTime = moment(state.changeItem.payTime).format(
        'YYYY-MM-DD'
      );

      console.log(data);
    };

    Promise.all([loadOrderItems(), loadOrderItems_2()]).then((result) => {
      console.log(result[0].items);
      console.log(result[1].items);

      result[0].items.filter((set) => {
        if (set.ccg_id === context.root.$route.params.cod_id) {
          groupByOrder(result[0].items);
        }
      });
      result[1].items.filter((set) => {
        if (set.crf_id === context.root.$route.params.cod_id) {
          groupByOrder(result[1].items);
        }
      });

      // groupByOrder(result[0].items);
      // groupByOrder(result[1].items);
    });

    return {
      ...toRefs(state),
      totalFee,
    };
  },
};
</script>

<style></style>
