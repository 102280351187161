<template>
  <div class="wrap">
    <back-title-basket :title="$t('CancelList.CancelDetail')" />
    <div class="layout_body box_p60">
      <div class="list_layout status detail_condition">
        <div class="group_order_list">
          <div class="inner border_bottom flex_col">
            <span v-if="orderDate" class="font2 colora8">
              {{ orderDate }}
            </span>
            <strong v-if="order"
              >{{ $t('OrderInquiry.OrderNumber') }} {{ order.order_id }}</strong
            >
          </div>
          <order-item
            v-for="item in items"
            :key="item.cod_id"
            :isShowOrder="false"
            :item="item"
          />
        </div>
      </div>
      <div class="border_top mt20">
        <h6 class="allPage_tit">{{ $t('CancelList.CancelReason') }}</h6>
        <div class="inner p04 table_wrap ver2">
          <div>
            <!--취소사유-->
            <table class="table_box">
              <colgroup>
                <col style="width: 27%" />
                <col style="width: 73%" />
              </colgroup>
              <tbody>
                <tr>
                  <th rowspan="2">{{ $t('CancelList.CancelReason') }}</th>
                  <!--취소 사유에 따라서 배송비 달라짐 api 태워야댐-->
                  <td v-if="cancelItems">
                    {{
                      cancelItems.cancel_reason === 'etc'
                        ? '기타(상세사유)'
                        : cancelItems.cancel_reason
                    }}
                  </td>
                </tr>
                <tr>
                  <td v-if="cancelItems">{{ cancelItems.cancel_memo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="border_top mt30">
        <!--환불정보-->
        <h6 class="allPage_tit">{{ $t('CancelList.RefundInfo') }}</h6>
        <div class="inner p04 btw_mbtn">
          <div class="table_wrap ver3 pb10">
            <div>
              <table class="table_box">
                <colgroup>
                  <col style="width: 27%" />
                  <col style="width: 73%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ $t('CancelList.CancelDate') }}</th>
                    <td v-if="cancelItems">
                      {{
                        cancelItems.cancel_complete_dtm
                          ? cancelItems.cancel_complete_dtm
                          : '-'
                      }}
                    </td>
                  </tr>
                  <tr v-if="cancelItems && order.status_name !== '입금대기'">
                    <th>{{ $t('CancelList.RefundType') }}</th>
                    <!-- 무통장 입금 -->
                    <td
                      v-if="
                        cancelItems.payMethod === 'VBANK' &&
                        order.status_name !== '입금대기'
                      "
                    >
                      무통장 입금 ₩
                      {{
                        cancelItems.cancel_price
                          ? Number(cancelItems.cancel_price).numberFormat()
                          : '0'
                      }}<br />
                      포인트 {{ cancelItems.use_point_value }}P<br />
                      쿠폰 {{ cancelItems.use_coupon_value }}P
                    </td>
                    <td v-if="cancelItems.payMethod === 'CARD'">
                      {{ cancelItems.payName }} ₩
                      {{
                        cancelItems.cancel_price
                          ? Number(cancelItems.cancel_price).numberFormat()
                          : '0'
                      }}
                      <br />
                      포인트 {{ cancelItems.use_point_value }}P<br />
                      쿠폰 {{ cancelItems.use_coupon_value }}₩
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('CancelList.DeductionAmount') }}</th>
                    <td>
                      ₩
                      {{
                        cancelItems
                          ? cancelItems.totalDiscount.numberFormat()
                          : ''
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th>결제금액</th>
                    <td v-if="cancelItems">
                      ₩
                      {{
                        cancelItems.total_price
                          ? Number(cancelItems.total_price).numberFormat()
                          : '0'
                      }}
                    </td>
                  </tr>
                  <tr class="refund_pri">
                    <th>{{ $t('CancelList.RefundAmount') }}</th>
                    <td v-if="cancelItems">
                      ₩
                      {{
                        cancelItems.cancel_price &&
                        order.status_name !== '입금대기'
                          ? Number(cancelItems.cancel_price).numberFormat()
                          : '0'
                      }}
                    </td>
                  </tr>
                  <tr class="evidence">
                    <th>{{ $t('OrderInquiry.Evidence') }}</th>
                    <td class="evidence p0">
                      <a
                        v-if="cancelItems"
                        :href="`https://npg.nicepay.co.kr/issue/IssueLoader.do?type=${cancelItems.rcptType}&TID=${cancelItems.tid}`"
                        class="black_btn"
                        onClick="window.open(this.href, '', 'width=400, height=430'); return false;"
                        target="_blank"
                      >
                        {{ $t('CancelList.Cancellation') }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="mb30 btw_btn">
          <router-link class="white_btn font2" to="/mypage/status-change-order"
            >{{ $t('Ect.List') }}
          </router-link>
          <router-link class="black_btn font2" to="/"
            >{{ $t('Ect.Main') }}
          </router-link>
        </div>
      </div>
      <div class="border_top mt30">
        <h6 class="allPage_tit">{{ $t('CancelList.CancelTitle1') }}</h6>
        <div class="inner p04 btw_mbtn">
          <div class="table_wrap ver2">
            <div>
              <table class="table_box">
                <colgroup>
                  <col style="width: 27%" />
                  <col style="width: 73%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>{{ $t('CancelList.CreditCard') }}</th>
                    <td>{{ $t('CancelList.Cancel') }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('CancelList.Payment') }}</th>
                    <td>
                      {{ $t('CancelList.PurchaseCancel') }}
                    </td>
                  </tr>
                  <tr>
                    <th v-html="$t('CancelList.VirtualAccount')"></th>
                    <td>{{ $t('CancelList.NextDay') }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('CancelList.RealTimeTransfer') }}</th>
                    <td>{{ $t('CancelList.RealTimeDeposit') }}</td>
                  </tr>
                  <tr class="evidence">
                    <th>{{ $t('CancelList.PhonePayment') }}</th>
                    <td v-html="$t('CancelList.PhoneDescription')"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import OrderAPI from '@/apis/OrderAPI';
import { reactive, toRefs } from 'vue-demi';
import SildFooter from '../../../components/layouts/components/footers/SildFooter.vue';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import DateUtils from '@/utils/DateUtils';
import useUserInfo from '../../../composables/user/useUserInfo';
import moment from 'moment';
import OrderItem from '../../../components/ui/OrderItem/OrderItem.vue';
import { cancelStatus } from '@/consts/OrderStatus';
import PaymentAPI from '@/apis/PaymentAPI';

export default {
  components: { BackTitleBasket, SildFooter, OrderItem },
  setup(_, context) {
    const { info } = useUserInfo();

    const state = reactive({
      items: null,
      cancelOrder: null,
      order: null,
      orderDate: null,
      cancelItems: null,
      selectedProof: null,
      isShowProof: false,
      isShowRequestProof: false,
    });

    const groupByOrder = (orders) => {
      const items = {};
      orders.forEach((item) => {
        item.is_detail = true;
        item.order = state.order;
        item.delivery_price = state.order.delivery_price;
        console.log('%%%%%%%%%%%%%');
        console.log(state.order.order_id);
        console.log(state.order.ins_dtm);
        console.log('%%%%%%%%%%%%%');
        state.orderDate = moment(state.order.ins_dtm).format('YYYY-MM-DD');
        // 주문
        if (items[item.cod_id] === undefined) {
          items[item.cod_id] = {
            order_id: item.order_id,
            cod_id: item.cod_id,
            ins_dtm: item.ins_dtm,
            use_point: state.order.use_point,
            delivery_price: state.order.delivery_price,
            provider: {},
          };
        }
        console.log(items);
        const order = items[item.cod_id];
        console.log(order);
        // 브랜드
        if (order['provider'][item.den_id] === undefined) {
          order['provider'][item.den_id] = {
            den_id: item.den_id,
            den_name: item.den_name,
            seller_picture: item.seller_picture,
            img_url: item.img_url,
            cs_no: item.cs_no,
            items: [],
          };
        }
        console.log(item, 'itemitemitemitemitem');
        order['provider'][item.den_id].items.push(item);
      });

      const groupItems = [];

      Object.keys(items).forEach((key) => {
        groupItems.push(items[key]);
      });

      groupItems.sort((a, b) => {
        const createDateA = DateUtils.dttmToNumber(a.ins_dtm);
        const createDateB = DateUtils.dttmToNumber(b.ins_dtm);

        return createDateB - createDateA;
      });

      if (params.page == 0) {
        state.items = [];
      }
      state.items = [...state.items, ...groupItems];

      state.items = state.items.filter((item) => {
        return item.cod_id === context.root.$route.params.cod_id;
      });
      console.log(
        state.items,
        'state.cancelOrderstate.cancelOrderstate.cancelOrder'
      );
    };

    const params = {
      order_id: context.root.$route.params.order_id,
      mem_id: info?.mem_id,
      page: 0,
      limit: 1000,
      status: cancelStatus,
    };

    const loadOrderItems = async () => {
      const { data } = await OrderAPI.getMyOrderItems(params);

      state.total = data.total;

      const order = data.orders[0];

      // order.payTime = moment(`20${order.applDate} ${order.applTime}`).format(
      //   'YYYY-MM-DD HH:mm:ss'
      // );

      console.log(data.result_data);
      state.order = order;
      console.log(state.order.order_id);
      console.log(order, '<----order');
      groupByOrder(data.result_data);
    };
    const loadCancelItems = async () => {
      const { data } = await PaymentAPI.getPaymentRequestCancel({
        order_id: context.root.$route.params.order_id,
        mem_id: info?.mem_id,
      });
      const { cod_id } = context.root.$route.params;
      const cancelItem = data.data.find((item) => item.cod_id === cod_id);
      if (cancelItem) {
        state.cancelItems = cancelItem;
        state.cancelItems.totalDiscount =
          Number(cancelItem.use_coupon_value) +
          Number(cancelItem.use_point_value);
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');
        console.log(state.cancelItems);
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!');
      }
    };
    loadCancelItems();
    loadOrderItems();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style></style>
