<template>
  <div class="wrap">
    <back-title-basket :title="$t('CancelList.CancelList')" />

    <div class="layout_body box_p60">
      <div class="list_layout status">
        <period-search :value="[]" @change="handleChangeGigan" />
        <div v-if="statistics" class="inner ptb10 border_top">
          <div class="ico_desc delivery">
            <ul id="myBtnContainer" class="disflex change_order">
              <li class="active">
                <div>
                  <div class="desc_img cancel"></div>
                  <div class="desc_txt" @click="handleChangeStatus('cancel')">
                    <p>{{ $t('MyStyle.Cancel') }}</p>
                    <p>{{ statistics['cancel'] }}</p>
                  </div>
                </div>
              </li>
              <li class="current">
                <div>
                  <div class="desc_img exchange"></div>
                  <div class="desc_txt" @click="handleChangeStatus('change')">
                    <p>{{ $t('CustomerService.Exchange') }}</p>
                    <p>{{ statistics['change'] }}</p>
                  </div>
                </div>
              </li>
              <li class="current">
                <div>
                  <div class="desc_img return"></div>
                  <div class="desc_txt" @click="handleChangeStatus('refund')">
                    <p>{{ $t('CustomerService.Return') }}</p>
                    <p>{{ statistics['refund'] }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--주문내역이 없을 때ㄱ-->
        <p
          v-if="items.length === 0"
          class="middle_txt"
          style="padding-bottom: 43px"
        >
          {{ $t('CancelList.CancelList') }}
        </p>
        <!--1.전체목록 시작-->
        <div v-else class="order_list_layout">
          <order-item
            v-for="item in items"
            :key="item.cod_id"
            :item="item"
            :type="getOrderType(item)"
          />
          <button
            v-show="hasMore"
            class="more"
            type="button"
            @click="handleClickMore"
          >
            {{ $t('Ect.More') }}
          </button>
        </div>

        <!--1.전체목록 끝-->
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('CancelList.CancelTitle1') }}</h6>
          <div class="inner p04 btw_mbtn">
            <div class="table_wrap ver2">
              <div>
                <table class="table_box">
                  <colgroup>
                    <col style="width: 27%" />
                    <col style="width: 73%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ $t('CancelList.CreditCard') }}</th>
                      <td>{{ $t('CancelList.Cancel') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.Payment') }}</th>
                      <td>
                        {{ $t('CancelList.PurchaseCancel') }}
                      </td>
                    </tr>
                    <tr>
                      <th v-html="$t('CancelList.VirtualAccount')"></th>
                      <td>{{ $t('CancelList.NextDay') }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t('CancelList.RealTimeTransfer') }}</th>
                      <td>{{ $t('CancelList.RealTimeDeposit') }}</td>
                    </tr>
                    <tr class="evidence">
                      <th>{{ $t('CancelList.PhonePayment') }}</th>
                      <td v-html="$t('CancelList.PhoneDescription')"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('CancelList.CancelTitle2') }}</h6>
          <div class="inner">
            <ul class="step_list">
              <li class="bg_f7 font2">
                <strong>STEP 01</strong>
                <p>{{ $t('CancelList.CancelStep1') }}</p>
                <p></p>
              </li>

              <li class="bg_f7 font2">
                <strong>STEP 02</strong>
                <p>
                  {{ $t('CancelList.CancelStep2') }}
                </p>

                <p></p>
              </li>

              <li class="bg_f7 font2">
                <strong>STEP 03</strong>
                <p>
                  {{ $t('CancelList.CancelStep3') }}
                </p>
                <p></p>
              </li>
            </ul>
          </div>
        </div>
        <div class="border_top">
          <h6 class="allPage_tit">{{ $t('CancelList.CancelTitle3') }}</h6>
          <div class="inner">
            <ul class="step_list">
              <li class="bg_f7 font2">
                <strong>STEP 01</strong>
                <p>{{ $t('CancelList.ReturnStep1') }}</p>
                <p></p>
              </li>

              <li class="bg_f7 font2">
                <strong>STEP 02</strong>
                <p>
                  {{ $t('CancelList.ReturnStep2') }}
                </p>
                <p></p>
              </li>

              <li class="bg_f7 font2">
                <strong>STEP 03</strong>
                <p>
                  {{ $t('CancelList.ReturnStep3') }}
                </p>
                <p></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import OrderAPI from '../../../apis/OrderAPI';
import PeriodSearch from '../../../components/Inputs/PeriodSearch/PeriodSearch.vue';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
import useUserInfo from '../../../composables/user/useUserInfo';
import DateUtils from '@/utils/DateUtils';
import OrderItem from '../../../components/ui/OrderItem/OrderItem.vue';
import {
  cancelStatus,
  changeStatus,
  returnStatus,
} from '../../../consts/OrderStatus';

export default {
  components: { BackTitleBasket, PeriodSearch, OrderItem },
  setup() {
    const { info } = useUserInfo();

    const state = reactive({
      items: [],
      statistics: null,
      hasMore: true,
      total: 0,
      orders: null,
      setItems: null,
    });

    const params = {
      mem_id: info?.mem_id,
      startDate: null,
      endDate: null,
      status: 'cancel',
      page: 0,
      limit: 10,
    };

    const groupByOrder = (orders) => {
      const items = {};

      orders.forEach((item) => {
        //   item.order = state.orders.find(
        //     (order) => order.order_id === item.order_id
        //   );
        console.log(item);

        // 주문
        if (items[item.cod_id] === undefined) {
          items[item.cod_id] = {
            order_id: item.order_id,
            ins_dtm: item.ins_dtm,
            // use_point: item.order.use_point,
            // delivery_price: item.order.delivery_price,
            status: item.status,
            provider: {},
          };
        }

        const order = items[item.cod_id];

        // 브랜드
        if (order['provider'][item.den_id] === undefined) {
          order['provider'][item.den_id] = {
            den_id: item.den_id,
            den_name: item.den_name,
            seller_picture: item.seller_picture,
            img_url: item.img_url,
            cs_no: item.cs_no,
            items: [],
          };
        }

        order['provider'][item.den_id].items.push(item);
      });

      const groupItems = [];

      Object.keys(items).forEach((key) => {
        groupItems.push(items[key]);
      });

      groupItems.sort((a, b) => {
        const createDateA = DateUtils.dttmToNumber(a.ins_dtm);
        const createDateB = DateUtils.dttmToNumber(b.ins_dtm);

        return createDateB - createDateA;
      });

      // if (params.page == 0) {
      //   state.items = [];
      // }

      state.items = [...state.items, ...groupItems];
      state.items = state.items.filter((item) => {
        return item.status !== 'DELIVERY_COMPLETE' && item.status !== 'PAYMENT';
      });
      console.log(state.items);
    };
    // 상태값 total
    const loadDatas = async () => {
      const { data } = await OrderAPI.getMyOrderItemsByStatus(params);

      state.total = data.total;
      // state.orders = data.orders;

      groupByOrder(data.items);

      state.hasMore = state.total > state.items.length && params.status;
    };

    const loadStatusTotal = async () => {
      const { data } = await OrderAPI.getMyOrderItemsTotal(params);
      const statistics = { cancel: '-', refund: '-', change: '-' };
      Object.keys(data.total).forEach((item) => {
        statistics[item] = data.totalByDate[item];
      });
      state.statistics = statistics;
    };

    return {
      ...toRefs(state),
      handleChangeGigan([startDate, endDate]) {
        params.startDate = startDate;
        params.endDate = endDate;

        loadDatas();
        loadStatusTotal();
      },
      handleClickMore() {
        params.page++;

        loadDatas();
      },
      handleChangeStatus(status) {
        state.items = [];
        params.page = 0;
        params.status = status;
        loadDatas();
        state.hasMore = false;
      },
      getOrderType(item) {
        if (cancelStatus.indexOf(item.status) > -1) {
          return 'cancel';
        } else if (
          changeStatus.indexOf(item.status) > -1 ||
          returnStatus.indexOf(item.status) > -1
        ) {
          return 'change/return';
        }

        return 'cancel';
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.more {
  border: 1px solid #ccc;
  padding: 10px 0;
  text-align: center;
  width: calc(100% - 40px);
  margin: 0 auto 20px auto;
  display: block;
}
</style>
