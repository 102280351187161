<template>
  <div class="wrap">
    <back-title-basket :title="$t('Mypage.DocumentEvidence')" />
    <div class="layout_body box_p60">
      <div class="prove_txt mb20">
        <p class="mt20 mb20">
          세금계산서 및 현금영수증은<br />
          주문하신 해당월 기준 익월 8일까지만 신청가능합니다.
        </p>
        <p>(예 : 구매일-2017년 1월 12일, 2017년 2월 8일까지만 신청가능)</p>
      </div>
      <!--      <div class="search_box">-->
      <!--        <input-->
      <!--          v-model="keyword"-->
      <!--          type="search"-->
      <!--          :placeholder="$t('CustomerService.SearchPlaceholder')"-->
      <!--        />-->
      <!--        <button type="submit" class="serch" @click="handleClickSearch"></button>-->
      <!--      </div>-->
      <div class="list_layout status">
        <period-search :value="[]" @change="handleChangeGigan" />

        <!--주문내역이 없을 때ㄱ-->
        <p v-if="items.length === 0" class="middle_txt">
          {{ $t('Mypage.DocumentEvidence') }}
        </p>
        <!--1.전체목록 시작-->
        <div v-else class="order_list_layout">
          <order-item v-for="item in items" :key="item.order_id" :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import OrderAPI from '../../../apis/OrderAPI';
import useUserInfo from '../../../composables/user/useUserInfo';
import DateUtils from '@/utils/DateUtils';
import PeriodSearch from '../../../components/Inputs/PeriodSearch/PeriodSearch.vue';
import BackTitleBasket from '../../../components/layouts/components/headers/BackTitleBasket.vue';
// import { deliveryStatus } from '../../../consts/OrderStatus';
import OrderItem from '../../../components/ui/OrderItem/OrderItem.vue';

export default {
  components: { PeriodSearch, BackTitleBasket, OrderItem },
  setup() {
    const { info } = useUserInfo();

    const state = reactive({
      items: [],
      keyword: '',
      hasMore: true,
      total: 0,
      orders: null,
    });

    const params = {
      mem_id: info?.mem_id,
      startDate: null,
      endDate: null,
      page: 0,
      limit: 10,
      status: ['COMPLETE'],
    };

    const groupByOrder = (orders) => {
      const items = {};

      orders.forEach((item) => {
        item.order = state.orders.find(
          (order) => order.order_id === item.order_id
        );

        // 주문
        if (items[item.order_id] === undefined) {
          items[item.order_id] = {
            order_id: item.order_id,
            ins_dtm: item.ins_dtm,
            use_point: item.order.use_point,
            delivery_price: item.order.delivery_price,
            status: item.status,
            provider: {},
          };
        }

        const order = items[item.order_id];

        // 브랜드
        if (order['provider'][item.den_id] === undefined) {
          order['provider'][item.den_id] = {
            den_id: item.den_id,
            den_name: item.den_name,
            seller_picture: item.seller_picture,
            cs_no: item.cs_no,
            items: [],
          };
        }

        order['provider'][item.den_id].items.push(item);
      });

      const groupItems = [];

      Object.keys(items).forEach((key) => {
        groupItems.push(items[key]);
      });

      groupItems.sort((a, b) => {
        const createDateA = DateUtils.dttmToNumber(a.ins_dtm);
        const createDateB = DateUtils.dttmToNumber(b.ins_dtm);

        return createDateB - createDateA;
      });

      if (params.page == 0) {
        state.items = [];
      }

      state.items = [...state.items, ...groupItems];
    };

    const loadDatas = async () => {
      const { data } = await OrderAPI.getMyOrderItems(params);

      state.total = data.total;
      state.orders = data.orders;

      groupByOrder(data.result_data);

      state.hasMore = state.total > state.items.length && !params.status;
    };

    return {
      ...toRefs(state),
      handleChangeGigan([startDate, endDate]) {
        params.startDate = startDate;
        params.endDate = endDate;

        loadDatas();
      },
    };
  },
};
</script>

<style scoped>
.search_box {
  padding: 0 14px;
}
</style>
